import React from "react";

export const Contact = () => {
  return (
    <>
      <section
        id="contact us"
        style={{ height: "80vh" }}
        className="d-flex align-items-center contact"
      >
        <div className="container-fluid ">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-6 col-sm-6 d-none d-sm-flex justify-content-center align-items-center">
              <img
                src="/contact_icon/contact-us.png"
                className="img-fluid img-lg-size"
              />
            </div>

            <div className="col-xl-5 col-lg-6 col-sm-6 col-10 d-flex flex-column justify-content-evenly align-items-center align-items-sm-start">
              <h1 className="fw-bold" style={{ color: "#3E3E40" }}>
                Contact Us!
              </h1>

              <div className="col-xl-7 col-lg-6 col-sm-6 d-flex d-sm-none justify-content-center align-items-center">
                <img
                  src="/contact_icon/contact-us.png"
                  className="img-fluid img-lg-size"
                  style={{ width: "50%" }}
                />
              </div>
              <p className="py-2 pt-5 pt-sm-0 ">
                <h4>Got Any Question?</h4>
                <span className="gotAnyQuestion">
                  Feel free to tell us your inquiries and concerns!
                </span>
              </p>
              <p>
                <a
                  href="https://www.google.com/maps?q=301+The+Greenery,+Pope+John+Paul+II+Avenue,+Kasambagan,+Cebu+City,+Philippines"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span className="fs-4">Address: </span>
                  <span className="contactAddress">
                    301 The Greenery, Pope John Paul II Avenue, Kasambagan, Cebu
                    City, Philippines
                  </span>
                </a>
              </p>

              <p>
                <a
                  href="tel:+639173276230"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span className="fs-4">Contact number: </span>
                  <span className="contactNumber"> +63 917 327 6230</span>
                </a>
              </p>

              <button className="btnContactEmail mb-5">
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="mailto:info@mytsoftdevsolutions.com"
                >
                  info@mytsoftdevsolutions.com
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
