import React from "react";

// List of features
const featuresData = [
  {
    icon: "/images/features/carbon_dashboard.png",
    title: "UNIFIED DASHBOARD",
    descriptions: [
      "View recent activities, birthdays, anniversaries, announcements, and memorandums.",
      "Access employee lists with lacking requirements and company files.",
    ],
  },
  {
    icon: "/images/features/carbon_user-profile.png",
    title: "EMPLOYEE PROFILES",
    descriptions: [" Access comprehensive 201 files for each employee."],
  },
  {
    icon: "/images/features/formkit_time.png",
    title: "TIME AND ATTENDANCE",
    descriptions: [
      "Simplify timesheet management with manual entry or biometric uploads.",
    ],
  },
  {
    icon: "/images/features/nimbus_money.png",
    title: "PAYROLL MANAGEMENT",
    descriptions: [
      "Efficiently create payroll.",
      "Generate payslips and use a bank payroll template.",
    ],
  },

  {
    icon: "/images/features/clarity_employee-line.png",
    title: "EMPLOYEE SCHEDULING",
    descriptions: ["Effectively manage and organize employee schedules."],
  },
  {
    icon: "/images/features/healthicons_call-centre-outline.png",
    title: "COMMUNICATION HUB",
    descriptions: [
      "Utilize a centralized platform for memos, announcements, and general communication.",
    ],
  },

  {
    icon: "/images/features/fluent-mdl2_leave-user.png",
    title: "LEAVE AND REMOTE WORK",
    descriptions: [
      "Streamline leave requests and manage work from anywhere arrangements.",
    ],
  },
  {
    icon: "/images/features/solar_hand-money-linear.png",
    title: "EMPLOYEE LOANS",
    descriptions: ["Facilitate loan management for employeesssss."],
  },

  {
    icon: "/images/features/fluent_building-government-16-regular.png",
    title: "GOVERNMENT CONTRIBUTIONS",
    descriptions: ["Easily generate PhilHealth, SSS, and Pag-Ibig forms."],
  },
  {
    icon: "/images/features/carbon_gui-management.png",
    title: "MANAGEMENT TOOLS",
    descriptions: [
      "Efficiently manage branches, business units, company files, departments, holidays, leave and letter types, night differentials, positions, projects, and shifts.",
    ],
  },

  {
    icon: "/images/features/fluent-mdl2_report-library.png",
    title: "DETAILED REPORTING",
    descriptions: [
      "Obtain various reports including payroll, 13th-month, loans, performance, leave and WFH, and timesheet reports.",
    ],
  },
  {
    icon: "/images/features/ph_info.png",
    title: "SYSTEM SUPPORT",
    descriptions: [
      "Receive reliable support for system-related issues and queries.",
    ],
  },
];

export const Features = () => {
  return (
    <>
      <section id="features">
        <div>
          <div className="image-container">
            <img
              src="/images/features-bg.png"
              className="img-fluid w-100"
              alt="People"
            />
            {/* overlay for dimming bg*/}
            <div className="overlay">
              <div className="overlay">
                <div className="overlay-text">
                  <div className="text-white fs-5 pb-3 whathrconnect-text">
                    WHAT HRCONNECT OFFERS
                  </div>
                  <div className="text-white weveGot-text ">
                    We’ve got everything you need to streamline and elevate your
                    human resource management.
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Mapping features */}

          {featuresData.map((feature, index) =>
            index % 2 === 0 ? (
              <div className="row mx-0 py-4 features-items" key={index}>
                <div className="col-1"></div>
                <div className="col">
                  <div className="d-flex">
                    <div className="mx-3 pt-1">
                      <img
                        height={"35px"}
                        width={"35px"}
                        src={feature.icon}
                        alt={feature.title}
                      />
                    </div>
                    <div className="flex">
                      <div className="fs-5 fw-medium">{feature.title}</div>
                      <div className="d-flex">
                        <div className="flex">
                          {feature.descriptions.map((desc, i) => (
                            <div
                              key={i}
                              className="d-flex"
                              style={{ marginRight: "100px" }}
                            >
                              <span className="" style={{ marginRight: "5px" }}>
                                •
                              </span>
                              {desc}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {index + 1 < featuresData.length && (
                  <div className="col">
                    <div className="d-flex">
                      <div className="mx-3 pt-1">
                        <img
                          height={"35px"}
                          width={"35px"}
                          src={featuresData[index + 1].icon}
                          alt={feature.title}
                        />
                      </div>
                      <div className="flex">
                        <div className="fs-5 fw-medium">
                          {`${featuresData[index + 1].title}`}
                        </div>
                        <div className="d-flex">
                          <div className="flex">
                            {featuresData[index + 1].descriptions.map(
                              (desc, i) => (
                                <div
                                  key={i}
                                  className="d-flex "
                                  style={{ marginRight: "100px" }}
                                >
                                  <span style={{ marginRight: "5px" }}>•</span>
                                  {desc}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null
          )}
        </div>
      </section>
    </>
  );
};
