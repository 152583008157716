import React from "react";

export const About = () => {
  return (
    <>
      <section id="about">
        <div className="image-container">
          <img
            src="/images/people.png"
            className="img-fluid w-100"
            alt="People"
          />
          {/* overlay for dimming bg*/}
          <div className="overlay">
            <div className="overlay">
              <div className="overlay-text">
                <div className="text-white fs-5 pb-3 gettingKnow-text">
                  GETTING TO KNOW HRCONNECT
                </div>
                <div className="text-white hrConnect ">
                  HrConnect is an innovative solution reshaping human resource
                  management, serving as a central hub for all HR needs.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "90px", paddingBottom: "90px" }}>
          <div className="position-relative d-flex justify-content-center flex-column align-items-center">
            <p className="text-black fs-5 pb-1 pt-5 whyChoose-text">
              WHY CHOOSE HRCONNECT
            </p>
            <p className="text-center withHrConnect-text">
              With HrConnect, we can make processes easier for you.
            </p>
          </div>
          <div className="banner-card py-5">
            <img
              src="/images/gray-arrow.png"
              className="img-fluid"
              alt="People"
            />
            <div className="first-text">
              <p>STREAMLINES HUMAN RESOURCES PROCESSES</p>
            </div>

            <img
              src="/images/yellow-arrow.png"
              className="img-fluid"
              alt="People"
            />
            <div className="second-text">
              <p>AUTOMATES TASKS SUCH AS PAYROLL AND TIMEKEEPING</p>
            </div>

            <img
              src="/images/blueArrow.png"
              className="img-fluid"
              alt="People"
            />
            <div className="third-text">
              <p>CAN BE TAILORED TO MANAGEMENT’S RULES</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
