import React from "react";

export const Footer = () => {
  return (
    <>
      <div className="d-flex p-3" style={{ borderTop: "1px black solid" }}>
        <div className="">
          <div className="d-flex align-items-center">
            <img src="/images/logo.png" height="20" width="130" alt="Logo" />
            <div className="px-5 fs-5 yourGate-text">
              {/* Your Gateway to Seamless HR Solutions. */}
              Bridging your HR needs.
            </div>
          </div>
          <div>
            <div className="powered-text" style={{ fontWeight: "lighter" }}>
              Powered by MYT Softdev Solutions, Inc. @ 2024
            </div>
          </div>
        </div>
        <div className="ms-auto footer-icon" style={{ paddingRight: "5%" }}>
       
            <a
              href="https://www.facebook.com/hrconnect"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/footer/facebook_icon.png"
                height="25"
                alt="Facebook"
              />
            </a>
            <a href="hrconnect@mytdevcorp.com">
              <img
                src="/images/footer/email_icon.png"
                height="20"
                alt="Email"
                style={{ paddingLeft: "20px" }}
              />
            </a>
 
        </div>
      </div>
    </>
  );
};
