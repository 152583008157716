import React from "react";
import { Link as ScrollLink } from "react-scroll";

export const Home = () => {
  return (
    <>
      <section id="home" style={{ margin: 0, padding: 0 }}>
        <div>
          <div className="container-fluid homepage">
            <div className="row">
              <div
                className="col-md-6 d-flex flex-column justify-content-center mb-lg-5"
                style={{ paddingLeft: "50px" }}
              >
                <span
                  className="opacity-75 "
                  style={{ fontSize: "60px", fontWeight: "bold" }}
                >
                  EXPLORE
                </span>
                <img
                  src="/images/logo.png"
                  className="mb-2 home-logo"
                  width="320"
                  height="60"
                  alt="Logo"
                />
                <div className="d-flex align-content-center gap-3 py-4">
                  <span className="fw-bold">BY</span>
                  <img
                    src="/images/myt.png"
                    width="100"
                    height="25"
                    alt="Logo"
                  />
                </div>
                <p className="fs-5 pb-2">
                  Transform your HR management effortlessly from streamlined
                  processes to automated payroll and customizable solutions
                  aligned with management rules.
                </p>
                <ScrollLink
                  to="features"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <button className="btnHomeLearnMore mb-5">Learn More</button>
                </ScrollLink>
              </div>
              <div className="col-md-6 p-0">
                <img
                  src="/images/file.png"
                  className="img-fluid w-100 h-100 file-img"
                  alt="Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
