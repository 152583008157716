import logo from "./logo.svg";
import "./App.css";
import { Navbar } from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import { Home } from "./components/pages/Home";
import { About } from "./components/pages/About";
import { Features } from "./components/pages/Features";
import { Pricing } from "./components/pages/Pricing";
import { Contact } from "./components/pages/Contact";
import { Footer } from "./components/Footer";
import MessengerCustomerChat from "react-messenger-customer-chat";

function App() {
  return (
    <>
      <Navbar />
      <Home />
      <About />
      <Features />
      {/* <Pricing /> */}
      <Contact />
      <Footer />
      <MessengerCustomerChat
        themeColor="#57C7DC"
        pageId="256304630888920"
        appId="362642376543074"
        loggedOutGreeting="Welcome to HRConnect, feel free to ask question!"
        loggedInGreeting="Ask any question"
      />

      {/* 
        <Route path="/home" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/features" element={<Features />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} /> */}
    </>
  );
}

export default App;
