import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

export const Navbar = () => {
  const [mobile, setMobile] = useState(true);
  const [items, setItems] = useState([
    "Home",
    "About",
    "Features",
    // "Pricing",
    "Contact Us",
  ]);

  useEffect(() => {
    const handleResize = () => {
      setItems((items) => {
        return window.innerWidth <= 425
          ? items.map((item) => (item === "Pricing" ? "Price" : item))
          : ["Home", "About", "Features", "Pricing", "Contact Us"];
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="bg-white">
      <div className="custom-navbar d-flex justify-content-between align-items-center px-5 py-2">
        <div>
          <ScrollLink to="home" spy={true} smooth={true} duration={500}>
            <img src="/images/logo.png" height="20" width="130" alt="Logo" />
          </ScrollLink>
        </div>
        <div className="menu-items">
          <ul className="d-flex gap-4 align-items-center list-unstyled m-0 p-0">
            {items.map((item) => (
              <li key={item} className="d-flex align-items-center">
                <ScrollLink
                  to={item.toLowerCase()}
                  spy={true}
                  smooth={true}
                  duration={500}
                  className={`px-3 py-1 text-decoration-none fs-5 nav-link hovered`}
                >
                  {item}
                </ScrollLink>
              </li>
            ))}
          </ul>
        </div>
        <button
          className="burger-menu"
          style={{}}
          onClick={() => setMobile(!mobile)}
        >
          {mobile ? <AiOutlineMenu /> : <AiOutlineClose />}
        </button>
      </div>
      {!mobile && (
        <div>
          <ul className="d-flex flex-column gap-2 align-items-center list-unstyled m-0 p-0">
            {items.map((item) => (
              <li key={item} className="d-flex w-100">
                <ScrollLink
                  to={item.toLowerCase()}
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="px-3 py-1 text-decoration-none text-center fs-5 nav-link w-100 hovered"
                >
                  {item}
                </ScrollLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
};
